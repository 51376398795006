<template>
  <div id="app">
    <Header />
    <router-view />
    <FloatManage />
    <Foot />
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import Foot from "./components/Foot.vue";
import FloatManage from "./components/FloatManage.vue";
export default {
  data() {
    return {};
  },
  components: {
    Header,
    Foot,
    FloatManage,
  },
};
</script>
<style lang="scss" scoped></style>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.bread-crumb {
  max-width: 1200px;
  margin: 20px auto 30px;
  background-color: transparent;
  overflow: hidden;
  .el-breadcrumb__item {
    font-size: 18px;
    cursor: pointer;
    span {
      cursor: pointer;
    }
  }
}
</style>
