<template>
  <div class="header">
    <div class="left">
      <div class="logo-wrapper">
        <div class="logo">
          <img
            :src="
              myBusiness && myBusiness.logo != null
                ? `https://tot.xlteacher.cn${myBusiness.logo}`
                : defaulHeader
            "
            alt=""
          />
        </div>
        <span class="logo_title">{{
          myBusiness && myBusiness.name != "" ? myBusiness.name : ""
        }}</span>
      </div>
      <div class="search">
        <el-input
          class="search_in"
          v-model="searchVal"
          :placeholder="`请输入${search_menu == 1 ? '课程' : '标准规范'}名称`"
          @keyup.enter.native="globalSearch"
        >
        </el-input>
        <div class="search_but">
          <p class="search_but_text" @click="globalSearch">搜索</p>
        </div>
      </div>
      <div class="right">
        <span
          class="please"
          style="display: inline-block; display: flex"
          v-if="!userInfo || JSON.stringify(userInfo) == '{}'"
          @click="loginMask"
        >
          <img style="width: 40px; height: 40px" :src="defaulHeader" alt="" />
          <p style="line-height: 10px; margin-left: 7px">请登录</p>
        </span>

        <el-dropdown trigger="click" style="cursor: pointer" v-else>
          <span class="el-dropdown-link" style="display: flex">
            <img
              style="
                width: 40px;
                height: 40px;
                border-radius: 50%;
                margin-top: 3px;
              "
              :src="
                userInfo && userInfo.head_img !== null
                  ? `https://tot.xlteacher.cn${userInfo.head_img}`
                  : defaulHeader
              "
              alt=""
            />
            <p class="header_name">
              {{ userInfo.name }}
              <i
                class="el-icon-arrow-down el-icon--right"
                style="margin-top: 3%; margin-left: 55%"
              ></i>
            </p>
          </span>
          <div class="mymenu">
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="updateInfo"
                >个人中心</el-dropdown-item
              >
              <el-dropdown-item @click.native="logOut"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>
    <div class="middle">
      <div class="nav-wrapper">
        <router-link
          to="/"
          style="wdith: 30px"
          class="home"
          :style="{
            color: $route.path == '/' ? '#fff' : '',
          }"
          >首页</router-link
        >
        <div
          target="_blank"
          href="https://www.saac.gov.cn/daj/fgbzk/fgbzk.shtml"
          style="margin-left: -10px"
          class="standard-wrapper"
        >
          <div>标准规范</div>
          <div class="standard-list">
            <p>
              <a
                target="_blank"
                href="https://www.saac.gov.cn/daj/fgbzk/fgbzk.shtml"
              >
                国家标准
              </a>
            </p>
            <p>协会标准</p>
            <p @click="$router.push('/standard')">分会标准</p>
          </div>
        </div>
        <div
          target="_blank"
          href="https://www.saac.gov.cn/daj/fgbzk/fgbzk.shtml"
          style="margin-left: -10px"
          class="standard-wrapper"
        >
          <div>考试练习</div>
          <div class="standard-list">
            <p @click="$router.push('/selectcert')">证书查询</p>
            <p @click="$router.push('/courlist')">考试入口</p>
          </div>
        </div>
        <!-- <router-link
          to="/courlist"
          class="exercise"
          style="margin-left: -10px"
          :style="{
            color: $route.query.tn
              ? '#fff'
              : $route.path == '/courlist'
              ? ''
              : '',
          }"
          >考试练习</router-link
        > -->
        <router-link
          to="/exchange"
          class="exercise"
          style="margin-left: -10px"
          :style="{
            color: $route.query.tn
              ? '#fff'
              : $route.path == '/exchange'
              ? ''
              : '',
          }"
          >人才交流</router-link
        >
        <router-link
          to="/teacherlist"
          class="exercise"
          style="margin-left: -10px"
          :style="{
            color: $route.query.tn
              ? '#fff'
              : $route.path == '/teacherlist'
              ? ''
              : '',
          }"
          >风采展示</router-link
        >
        <router-link
          to="/conference"
          class="exercise"
          style="margin-left: -10px"
          :style="{
            color: $route.query.tn
              ? '#fff'
              : $route.path == '/conference'
              ? ''
              : '',
          }"
          >会议展览</router-link
        >
        <router-link
          to="/branch"
          class="exercise"
          style="margin-left: -10px"
          :style="{
            color: $route.query.tn
              ? '#fff'
              : $route.path == '/branch'
              ? ''
              : '',
          }"
          >分会信息</router-link
        >
      </div>
    </div>
    <div class="c-b">
      <div class="certificate" ref="certificate">
        <span class="cert-name">{{ certInfo.name }}</span>
        <span class="cert-stime">{{ certInfo.start_time }}</span>
        <span class="cert-etime">{{ certInfo.end_time }}</span>
        <span class="cert-course-time">{{ certInfo.class_num }}</span>
        <span class="cert-identifier">{{ certInfo.cert_num }}</span>
        <span class="cert-ftime">{{ certInfo.end_time }}</span>
        <span class="cert-cname">{{ certInfo.cert_name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css"
import html2canvas from "html2canvas"

import defaulHeader from "../assets/images/default_header.png"
export default {
  data() {
    return {
      options: [
        {
          search_menu: "1",
          label: "课程",
        },
        // {
        //   search_menu: "2",
        //   label: "标准规范",
        // },
      ],
      search_menu: "1",
      searchVal: "",
      myHead: {},
      myBusiness: {},
      personImgUrl: "",
      defaulHeader: defaulHeader, //默认显示头像路径
      typeName: "", //

      certInfo: {},
    }
  },
  mounted() {
    this.getheadData()
    document.title = this.business ? this.business.name : ""
  },
  methods: {
    updateInfo() {
      this.$router.push("/personal")
    },
    logOut() {
      this.axios.post("/login/login_out").then((res) => {
        this.$router.push("/")
        localStorage.removeItem("eft")
        this.$store.dispatch("SET_EMPLOYEE_INFO", JSON.stringify({}))
      })
    },
    chengeSearchFlag(currentVal) {
      this.search_menu = currentVal
    },
    globalSearch() {
      if (this.searchVal === "") {
        this.$message.error("请输入搜索内容!")
        return
      }
      if (this.search_menu == 1) {
        this.$router.push(
          `/search?type=${this.search_menu}&search=${this.searchVal}`
        )
        if (this.$route.name === "Search") {
          this.$router.go(0)
        }
      }
      if (this.search_menu == 2) {
        this.$router.push(
          `/standard?type=${this.search_menu}&search=${this.searchVal}`
        )
        if (this.$route.name == "Standard") {
          this.$router.go(0)
        }
      }
      this.searchVal = ""
    },
    getheadData() {
      this.axios.post("/").then((res) => {
        this.myHead = res.data
        this.myBusiness = res.data.business
        if (res.data.is_has_cert == 0) {
          this.autoCert()
        }
      })
    },
    //  获取个人设置请求
    getPersonalSetData() {
      this.axios.post("index/my_settings").then((res) => {
        if (res.code == 0) {
          this.getPersonalSetData()
          this.getheadData()
        }
        console.log(res)
        this.realname = res.data.name
        this.personImgUrl = res.data.head_img
      })
    },

    getIndexData() {
      this.axios.get("/").then((res) => {
        console.log(1)
        if (
          res.data.user_info &&
          typeof this.$store.state.empInfo !== "Object"
        ) {
          this.$store.dispatch(
            "SET_EMPLOYEE_INFO",
            JSON.stringify(res.data.user_info)
          )
        }
        if (JSON.stringify(this.$store.state.business) === "{}") {
          this.$store.dispatch(
            "SET_BUSINESS_INFO",
            JSON.stringify(res.data.business)
          )
        }
      })
    },
    autoCert() {
      this.axios.post("/exam/automatic_cert").then((res) => {
        console.log(res)
        if (res.data.code == 1) {
          return
        } else {
          this.certInfo = res.data.info
          setTimeout(() => {
            this.createCert()
          }, 800)
        }
      })
    },
    createCert() {
      html2canvas(this.$refs.certificate).then((canvas) => {
        let base64 = canvas.toDataURL("image/png")
        let fd = new FormData()
        fd.append("pic", base64)
        this.axios.post("/exam/cert_pic", fd).then((res) => {})
      })
    },
    loginMask() {
      this.$store.state.loginMethods()
    },
  },
  computed: {
    userInfo() {
      if (JSON.stringify(this.$store.state.empInfo) !== "{}") {
        return JSON.parse(this.$store.state.empInfo)
      }
    },
    business() {
      if (JSON.stringify(this.$store.state.business) !== "{}") {
        return JSON.parse(this.$store.state.business)
      }
    },
  },
}
</script>
<style lang="scss">
:root {
  --animate-duration: 0.3s;
}
.search {
  .el-input,
  .search_in .el-input__inner {
    border-color: #dcdfe6 !important;
    border-radius: 0%;
    height: 40px;
  }
  .search_menu {
    .el-input .el-input__inner {
      border-color: #dcdfe6 !important;
      text-align: center;
      border-radius: 0;
      border-right: 0px;
    }
  }
}
</style>
<style lang="scss" scoped>
// header中去除eslint下划线
a {
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
}
.header {
  width: 100%;
  background: #ffffff;
  // padding: 0 40px;
  box-sizing: border-box;
  position: relative;
  .left {
    max-width: 1200px;
    height: 80px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-wrapper {
      display: flex;
      align-items: center;
      width: 50%;
      .logo {
        max-width: 15%;
        height: 100%;
        img {
          width: 100%;
          max-height: 100%;
        }
      }
      span {
        margin-left: 10px;
        font-size: 20px;
      }
    }
    .search {
      width: 360px;
      display: flex;
      // flex-shrink: 0;
      .search_but {
        width: 130px;
        height: 40px;
        font-size: 16px;
        color: #ffffff;
        background: #8bb9ff;
        .search_but_text {
          // margin-top: 8px;
          // margin-left: 17px;
          margin: 0;
          line-height: 40px;
          text-align: center;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
    .right {
      height: 45px;
      position: absolute;
      right: 8%;
      color: #000;
      .please {
        margin-top: 5px;
        margin-left: 10px;
        cursor: pointer;
      }
      & > img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        object-fit: cover;
      }
      .header_name {
        margin-left: 10px;
      }
      .el-icon--right {
        position: absolute;
        left: 50%;
      }
    }
  }
  .middle {
    width: 100%;
    height: 60px;
    background-color: #0168ad;

    .nav-wrapper {
      max-width: 1200px;
      height: 100%;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 400;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      text-align: center;
      a,
      & > div {
        display: block;
        color: #fff;
        width: 118px;
        height: 100%;
        line-height: 60px;
        cursor: pointer;
        &:hover {
          background-color: #db3126;
        }
      }
      .standard-wrapper {
        position: relative;

        .standard-list {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          position: absolute;
          top: 100%;
          left: 0;
          height: 0;
          color: #333;
          z-index: 10;
          overflow: hidden;
          transition: all 0.15s;
          background-color: rgba($color: #fff, $alpha: 0.9);
          p,
          a {
            margin: 0 auto;
            width: inherit;
            height: 30px;
            font-size: 14px;
            text-align: center;
            line-height: 30px;
            color: #333;
            &:hover {
              background-color: #db3126;
              color: #fff;
            }
          }
        }
        &:hover {
          .standard-list {
            height: 120px;
          }
        }
      }
    }
  }
}

.c-b {
  width: 0;
  height: 0;
  overflow: hidden;
  .certificate {
    width: 1080px;
    height: 1524px;
    background: url("../assets/images/cert.jpg") no-repeat;
    background-size: cover;
    position: relative;
    top: 100%;
    left: 0;
    // z-index: -99;
    span {
      font-size: 38px;
      // font-weight: 400;
      font-family: "楷体";
      position: absolute;
      color: #3a3a3a;
    }
    .cert-name {
      top: 37.1%;
      left: 19%;
    }
    .cert-stime {
      top: 37.1%;
      left: 42.5%;
      // font-size: 32px;
    }
    .cert-etime {
      top: 37.1%;
      left: 70%;
      // font-size: 32px;
    }
    .cert-course-time {
      top: 45.2%;
      right: 30%;
    }
    .cert-identifier {
      bottom: 22.3%;
      left: 30.5%;
      font-size: 26px;
    }
    .cert-ftime {
      left: 30.5%;
      bottom: 17.7%;
      font-size: 26px;
    }
    .cert-cname {
      right: 13%;
      bottom: 12%;
    }
  }
}
</style>
